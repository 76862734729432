import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import datepicker from "bootstrap-datepicker";
import autocomplete from "devbridge-autocomplete";

const images = require.context('../img', true)

import '../scss/main'

import '../src/search'