import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import select2 from 'select2';
import Scrollbar from 'smooth-scrollbar';
import moment from 'moment';

window.moment = moment

var callHeightFirst;
if ($(window).width() > 768) {
	callHeightFirst = 587;
}
else {
	callHeightFirst = 425;
}

function parseURL(url) {
  const parser = document.createElement('a');
  const searchObject = {};

  parser.href = url;

  const queries = parser.search.replace(/^\?/, '').split('&');
  for (let i = 0; i < queries.length; i++) {
    const split = queries[i].split('=');
    if (split[0].indexOf('[]') !== -1) {
      if(typeof searchObject[split[0]] === 'undefined') {
        searchObject[split[0]] = []
      }
      searchObject[split[0]].push(split[1])
    } else
      searchObject[split[0]] = split[1];
  }

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash
  };
}

$(document).ready(function() {
  $('.js-select').select2({
  	minimumResultsForSearch: -1,
    templateSelection: function(data) {
      return $('<b>' + data.text.replace(/(\w)\s+([^\s]+)/, "$1 <span>$2</span>") + '</b>');
    }
  });
  
	$('body').on('click', '.search-bt', function(event) {
		$('.header-search').addClass('active');
		$('.header-search input').focus();
		event.preventDefault();
	});
  
  $('body').on('click', '.one-side-subtit', function(event) {
    $(this).next('.one-side-descr').toggle();
    if($(this).next('.one-side-descr').is(':visible')) $(this).removeClass('active');
    else $(this).addClass('active');
    event.preventDefault();
  });
  
	$(document).click(function(e) {
    if (!$(e.target).is('.header-search, .header-search *, .autocomplete-city, .autocomplete-city *')) {
			$('.header-search').removeClass('active');
    }
	});
  
  if (document.querySelector('.scrollable-filter') !== null) {
    $( '.scrollable-filter' ).each(function( index ) {
      Scrollbar.init($(this)[0], { alwaysShowTracks: true});
    });
  }
  
  $(".categories_checkboxes").click(function() { 
    var categories = '';
    $(".categories_checkboxes").each(function( index ) {
      if($(this).is(':checked')) categories += 'categories[]=' + $(this).val() + '&';
    });
    var url = parseURL(decodeURI($('.more-btn').attr('href')));
    $.each(url.searchObject, function( key, value ) {
      if (key == 'page') value = '1';
      if (key != 'categories[]') 
        if (Array.isArray(value)) {
          for (var i = 0; i < value.length; i++) categories += key + '=' + value[i] + '&';
        } else
          categories += key + '=' + value + '&';
    })
    $('.cont-event-list, .one-filter-check').children().fadeTo("fast" , 0.5);
    $('.categories_checkboxes,.cities_checkboxes,.regions_checkboxes').attr('disabled','disabled');
    $.ajax(url.pathname + ((url.pathname.indexOf('.js') === -1) ? '.js' : '') + '?' + categories).done(function() {})
  });
  
  $(".cities_checkboxes").click(function() { 
    var categories = '';
    $(".cities_checkboxes").each(function( index ) {
      if($(this).is(':checked')) categories += 'cities[]=' + $(this).val() + '&';
    });
    var url = parseURL(decodeURI($('.more-btn').attr('href')));
    $.each(url.searchObject, function( key, value ) {
      if (key == 'page') value = '1';
      if (key != 'cities[]') 
        if (Array.isArray(value)) {
          for (var i = 0; i < value.length; i++) categories += key + '=' + value[i] + '&';
        } else
          categories += key + '=' + value + '&';
    })
    $('.cont-event-list, .one-filter-check').children().fadeTo("fast" , 0.5);
    $('.categories_checkboxes,.cities_checkboxes,.regions_checkboxes').attr('disabled','disabled');
    $.ajax(url.pathname + ((url.pathname.indexOf('.js') === -1) ? '.js' : '') + '?' + categories).done(function() {})
  });

  $(".regions_checkboxes").click(function() { 
    var categories = '';
    $(".regions_checkboxes").each(function( index ) {
      if($(this).is(':checked')) categories += 'regions[]=' + $(this).val() + '&';
    });
    var url = parseURL(decodeURI($('.more-btn').attr('href')));
    $.each(url.searchObject, function( key, value ) {
      if (key == 'page') value = '1';
      if (key != 'regions[]') 
        if (Array.isArray(value)) {
          for (var i = 0; i < value.length; i++) categories += key + '=' + value[i] + '&';
        } else
          categories += key + '=' + value + '&';
    })
    $('.cont-event-list, .one-filter-check').children().fadeTo("fast" , 0.5);
    $('.categories_checkboxes,.cities_checkboxes,.regions_checkboxes').attr('disabled','disabled');
    $.ajax(url.pathname + ((url.pathname.indexOf('.js') === -1) ? '.js' : '') + '?' + categories).done(function() {})
  });
    
  $('.sort-links').click(function(event) {
    var params = '';
    $('.sort-links').each(function() { $(this).parent().removeClass('active')});
    $('a.sort-links').each(function() { $(this).removeClass('active')});
    $('#mobile-sort-link-'  + (parseInt($(this).data('order')) - 1)).addClass('active');
    $('#sort-link-'  + (parseInt($(this).data('order')) - 1)).parent().addClass('active');
    var url = parseURL(decodeURI($('.more-btn').attr('href')));
    console.log(url)
    $.each(url.searchObject, function( key, value ) {
        if (key == 'page') value = '1';
        if (key != 'order')
          if (Array.isArray(value)) {
            for (var i = 0; i < value.length; i++) params += key + '=' + value[i] + '&';
          } else
            params += key + '=' + value + '&';
    })
    params += 'order=' + $(this).data('order');
    $('.cont-event-list, .one-filter-check').children().fadeTo("fast" , 0.5);
    $('.categories_checkboxes,.cities_checkboxes,.regions_checkboxes').attr('disabled','disabled');
    $.ajax(url.pathname + ((url.pathname.indexOf('.js') === -1) ? '.js' : '') + '?' + params).done(function() {})
    event.preventDefault();
  })
  
  var calendarEl = document.getElementById('calendar');

  var calendar = new Calendar(calendarEl, {
  	header: {
        left: 'prev',
        center: 'title',
        right : 'next'
    },
    plugins: [ dayGridPlugin ],
    contentHeight: callHeightFirst,
    showNonCurrentDates: false,
    fixedWeekCount: false,
    datesRender: function(info){
      if (moment(info.view.activeStart).isBefore(moment(),'day')) $(".fc-prev-button").hide();
      else $(".fc-prev-button").show();
      if (moment(info.view.activeEnd).isAfter(moment().add(1, 'year'),'day')) $(".fc-next-button").hide();
      else $(".fc-next-button").show();      
      $('.fc-day-top').filter(
        function(index){
        return moment( $(this).data('date') ).isBefore(moment(),'day') || moment( $(this).data('date') ).isAfter(moment().add(1, 'year'),'day')
      }).addClass('fc-disabled');
    },   
    navLinks: true,
    navLinkDayClick: function(date, jsEvent) {
      if (moment(date).isBefore(moment(),'day') || moment(date).isAfter(moment().add(1, 'year'),'day')) return;
      var month = '' + (date.getMonth() + 1),
          day = '' + date.getDate(),
          year = date.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      var dd = [year, month, day].join('-');
      if (document.location.href.indexOf('#') != -1) {
        document.location.href = document.location.href.substr(0, document.location.href.indexOf('#')) + '/' + dd + '/' + dd;
      } else {
        document.location.href = document.location.href + '/' + dd + '/' + dd;
      }
    }
  });

  if (typeof(calendarEl) != 'undefined' && calendarEl != null)
  {
    calendar.render();
  }
  
  function total_calc() {
      var sum = 0;
      $('.choose-ticket-table-line .js-select').each(function() {
        sum += parseFloat($(this).data('price')) * parseInt($(this).val());
      });
      $('#ticket-sum-total').text(sum.toFixed(2));
  }
  $('.choose-ticket-table-line .js-select').change(total_calc);
  
  $('#put_cart_btn').click(function(){
    var sum = 0; var total = 0;
    $('#line_item_form select[name^="variant"] option:selected').each(function() {
      sum += Number($(this).val());
      total += parseFloat($(this).parent().data('price')) * parseInt($(this).val());
      if (parseInt($(this).val()) > 0) {
        dataLayer.push({
          'event': 'add_to_cart',
          'ecommerce': {
            'currency': $('#currency_code').val(),
            'items': [{
              'item_name': $('#product_name').val(),
              'item_id': $('#product_sku').val(),
              'price': $(this).parent().data('price'),
              'item_brand': $('#product_brand').val(),
              'item_category': $('#product_cat').val(),
              'item_variant': $(this).parent().data('name'), 
              'quantity': $(this).val()
            }]
          }
        });
        dataLayer.push({
          'product_id': $('#product_sku').val()+'-'+$(this).parent().data('name'), 
          'product_name': $('#product_name').val(),
          'product_category': $('#product_cat').val(),
          'product_price': $(this).parent().data('price'),
          'product_quantity': $(this).val(),
          'cart_total': parseFloat($('#cart_total').val()) + total
        });
      }
    });
    if (parseInt($('#minimum_party_size').val()) > sum)
      $("#minWarnModal").modal('show');
    else if (parseInt($('#maximum_party_size').val()) < sum)
      $("#maxWarnModal").modal('show');
    else
      $("#line_item_form").submit();
    return false;
  });
  
  $(".time-bt").click(function(){
    var clicked = $(this);
    $(".time-bt").each(function() { $(this).removeClass("active");});
    $.get(document.location.href + '&time=' + $(this).data('time'), function( data ) {
      $('.choose-ticket-table').html(data);
      $('#ticket-sum-total').text("0.00");
      clicked.addClass("active");
      $('.choose-ticket-table-line .js-select').select2({minimumResultsForSearch: -1});
      $('.choose-ticket-table-line .js-select').change(total_calc);
    });
  }); 
});